import React from 'react'
import Layout from '../../components/Layout'
import { Helmet } from "react-helmet"
import { withPrefix } from "gatsby"

import facebook from '../../img/social/facebook.svg'
import instagram from '../../img/social/instagram.svg'
import twitter from '../../img/social/twitter.svg'
//import downloadFile from '../../downloads/Immunity-Meal-Plan-1.5.pdf'
//import PropTypes from 'prop-types'
//import { trackCustomEvent } from "gatsby-plugin-google-analytics"

//function handleMealPlanDownloadClicks(event) {
//  alert("downloaded from immunity meal plan.js")
  //gtag('event', 'xyz');
//}

const mealPlanFilename = "Immunity-Meal-Plan-071920.pdf";
const mealPlanPath = "../../downloads/" + mealPlanFilename;

const pageMetadata  = {
  title: 'The Immunity Meal Plan & Immunity Smoothie',
  description: 'Using whole foods to prevent or reduce symptoms, hospitalization, and deaths caused by coronavirus COVID-19'
}

function handlePrintMealPlanClick(e) {
  e.preventDefault();

  window.gtag('event', 'Click Button', {
    'event_category' : 'Meal Plan',
    'event_label' : 'Print Meal Plan'
  });
  document.location = mealPlanPath;

}

function handleDownloadMealPlanClick(e) {
  e.preventDefault();

  var link = document.createElement("a");
  // If you don't know the name or want to use
  // the webserver default set name = ''
  var uri = mealPlanPath;

  link.setAttribute('download', "");
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();


  window.gtag('event', 'Click Button', {
    'event_category' : 'Meal Plan',
    'event_label' : 'Download Meal Plan'
  });
  //alert("download 3");

}

export default () => (
  <Layout pageMetadata = {pageMetadata}>
  <section className="section section--gradient">
    <div className="container">
      <div className="columns">
        <div className="column is-10 is-offset-1">

          <div className="section">
          <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
          The IMMUNITY<span style={{fontSize:"20px",position:"relative", top:"-10px"}}>&trade;</span> Meal Plan & IMMUNITY<span style={{fontSize:"20px",position:"relative", top:"-10px"}}>&trade;</span>  Smoothie
          </h2>
          <p>Please scroll down to preview the meal plan. The Immunity Smoothie recipe is at the bottom of the meal plan. The meal plan is constantly being updated so please subscribe to our email and follow us on social media to get updates. Please freely share it with friends and family.</p>



          <div className="columns" style={{marginTop:"20px"}}>

          <div style={{textAlign: 'center', backgroundColorx: "#ff0000", positionx: "relative", top:"0px"}} className="column is-3x ef-button-report-effectivenessx">
          <a onClick={handlePrintMealPlanClick}  className="btn" to="" style={{marginTop: "0px", paddingTop: "9px", width: "210px", boxShadow: "none", backgroundColor: "#ff0000", color: "white"}}>
            See Printable Version
          </a>
          </div>

          <div class="column is-3x" style={{padding: "0px", backgroundColorx: "#ff0000"}}>
                          <div className="content has-text-centered ef-social-top-sectionx">
                                    Follow for updates
                          <div className="column is-4x social ef-social-top-sectionx has-text-centered">
                          <a title="facebook" href="https://www.facebook.com/EffectiveFoods" style={{backgroundColor: '#00693eff'}}>
                            <img
                              src={facebook}
                              alt="Facebook"
                              style={{ width: '1em', height: '1em', fillx: 'white', backgroundColorx: 'white', filter: 'invert(1)'}}
                            />
                          </a>
                          <a title="twitter" href="https://twitter.com/EffectiveFoods" style={{backgroundColor: '#00693eff'}}>
                            <img
                              className="fas fa-lg"
                              src={twitter}
                              alt="Twitter"
                              style={{ width: '1em', height: '1em', filter: 'invert(1)' }}
                            />
                          </a>
                          <a title="instagram" href="https://www.instagram.com/effectivefoods" style={{backgroundColor: '#00693eff'}}>
                            <img
                              src={instagram}
                              alt="Instagram"
                              style={{ width: '1em', height: '1em', filter: 'invert(1)' }}
                            />
                          </a>
                          </div>
                          </div>
        </div>


          <div style={{textAlign: 'center', backgroundColorx: "#ff0000", positionx: "relative", top:"0px"}} className="column is-3x ef-button-report-effectivenessx">


          <a onClick={handleDownloadMealPlanClick} className="btn" to={mealPlanPath} download style={{marginTop: "0px", paddingTop: "9px", width: "210px", boxShadow: "none", backgroundColor: "#ff0000", color: "white"}}>
            Download Plan (PDF)
          </a>

          </div>




            </div>



          <div align="center">
          <Helmet>
            <script src={withPrefix('subscribe-jotform.js')} type="text/javascript" />
          </Helmet>
          <iframe id="JotFormIFrame-201030653998154" class="ef-subscribe-form" title="Subscribe" onload="window.parent.scrollTo(0,0)" allowtransparency="true" allowfullscreen="true" allow="geolocation; microphone; camera" src="https://form.jotform.com/201030653998154" frameborder="0" scrolling="no" > </iframe>
          </div>




          <div align="center">
            <iframe title="Immunity Meal Plan" width="830" height="1200" align="center" src="https://docs.google.com/document/d/e/2PACX-1vQhaUtmw2QOgjSnM68yjW-sETWzA1_8vmtuhI7sLwKVgSwoX2mv1yZ5nMr72gBKqgINsTOGDxbTEQAN/pub?embedded=true"></iframe>
          </div>
          </div>

        </div>

      </div>
    </div>
</section>
  </Layout>
)
